import BaseService from "@/services/base-service";
import { handleError } from "@/utils/error";
import { PlaylistSubmission } from "@/types/playlist-submission";
import { PlaylistsFilter, ProAction } from "@/types/playlists";
import { getGAVariables } from "@/utils/helpers";

class PlaylistService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getPlaylistSubmissions(
    filters?: PlaylistsFilter,
  ): Promise<PlaylistSubmission[]> {
    try {
      const res = await this.get(
        `${this.getServiceContext}${
          // @ts-ignore
          filters ? "?" + new URLSearchParams(filters).toString() : ""
        }`,
      );
      // @ts-ignore
      if (res) return res.playlistSubmissions;
    } catch (error) {
      throw handleError(error);
    }
    return [];
  }

  public async submitMessage(
    id: string,
    message: string,
    progress: number,
  ): Promise<{ ok: boolean }> {
    try {
      return await this.post(`${this.getServiceContext}/${id}/message`, {
        message,
        progress: Math.floor(progress),
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async createPlaylistSubmission(
    trackId: string,
  ): Promise<PlaylistSubmission> {
    try {
      const analytics = await getGAVariables();
      return await this.post(`${this.getServiceContext}`, {
        trackId,
        analytics,
      });
    } catch (e) {
      throw handleError(e);
    }
  }

  public async getCount(filters: { [key: string]: string }): Promise<number> {
    try {
      return await this.get(
        `${this.getServiceContext}/count?${Object.keys(filters)
          .filter((key) => key.length > 0)
          .map((key) => `${key}=${filters[key]}`)
          .join("&")}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async list(filters: {
    [key: string]: string;
  }): Promise<PlaylistSubmission[]> {
    try {
      return await this.get(
        `${this.getServiceContext}?${Object.keys(filters)
          .filter((key) => key.length > 0)
          .map((key) => `${key}=${filters[key]}`)
          .join("&")}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getFilteredItemsCount(filters: {
    [key: string]: string;
  }): Promise<number> {
    try {
      return await this.get(
        `${this.getServiceContext}/count${
          filters ? "?" + new URLSearchParams(filters).toString() : ""
        }`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async proAction(id: string, proAction: ProAction, progress: number) {
    try {
      return await this.post(`${this.getServiceContext}/${id}/action`, {
        proAction,
        progress: Math.floor(progress),
      });
    } catch (e) {
      throw handleError(e);
    }
  }
}

export default PlaylistService;
