import { PlaylistsFilter, ProAction } from "@/types/playlists";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";
import PlaylistService from "@/services/playlist-service";
import { PlaylistSubmission } from "@/types/playlist-submission";
import { Sort } from "@/types/data-table";
import config from "@/config";
import { WritableComputedRef } from "nuxt/dist/app/compat/capi";
import { AnalyticsService } from "@/services/analytics-service";
import { Track } from "@/types/track";

interface PlaylistsStore {
  selectedSubmission?: PlaylistSubmission;
  artistSubmittedPlaylists: PlaylistSubmission[];
  itemsLoading: boolean;
  items: PlaylistSubmission[];
  allItemsLoaded: boolean;
  filters: PlaylistsFilter;
  pagination: {
    pageOffset: number;
    pageLength: number;
  };

  tmpSubmissionsCount: number;
  submissionsCount: number;
  isLoading: boolean;
  hasMore: boolean;
  infiniteScrollPageSize: number;
  total: number;
  sort: Sort;
  page: number;

  previousQuery: string;
  scrollPosition: number;
  selectedTrack?: Track;
}

const service = new PlaylistService("playlist-submission");
const analyticsService = new AnalyticsService("Analytics");

export const usePlaylistsStore = defineStore("playlists", {
  state: (): PlaylistsStore => ({
    selectedSubmission: undefined,
    artistSubmittedPlaylists: [],
    filters: { saved: false, archived: false },
    pagination: {
      pageOffset: 0,
      pageLength: 1000,
    },
    allItemsLoaded: false,
    itemsLoading: false,
    items: [],
    total: 0,

    tmpSubmissionsCount: 0,
    submissionsCount: 0,
    isLoading: false,
    hasMore: true,
    infiniteScrollPageSize: 50,
    sort: {
      column: "createdAt",
      order: -1,
    },
    page: 0,

    previousQuery: "",
    scrollPosition: 0,
    selectedTrack: undefined,
  }),
  actions: {
    clearAll() {
      this.itemsLoading = true;
      this.allItemsLoaded = false;
      this.items = [];
      this.filters = {};
      this.pagination = {
        pageOffset: 0,
        pageLength: 30,
      };
      this.submissionsCount = 0;
      this.infiniteScrollPageSize = 50;
      this.total = 0;
      this.hasMore = true;
      this.isLoading = false;
      this.page = 0;
      this.previousQuery = "";
      this.scrollPosition = 0;
      this.selectedTrack = undefined;
    },
    async getArtistSubmittedPlaylists(
      uri: string,
    ): Promise<PlaylistSubmission[]> {
      try {
        this.artistSubmittedPlaylists = await service.getPlaylistSubmissions();
        return this.artistSubmittedPlaylists;
      } catch (error) {
        throw handleError(error);
      }
    },
    setFilters(filters: PlaylistsFilter) {
      this.filters = filters;
    },
    setSelectedSubmission(submission?: PlaylistSubmission) {
      this.selectedSubmission = submission;
    },
    getNextIndex(submissionId: string) {
      const index = this.items.findIndex((item) => item._id === submissionId);
      return index + 1 === this.items.length ? index - 1 : index + 1;
    },
    async getItems() {
      try {
        if (this.allItemsLoaded) return;
        this.itemsLoading = true;
        // TODO: do some pagination in the future
        const playlistSubmissions = await service.getPlaylistSubmissions(
          this.filters,
        );
        this.allItemsLoaded = true;
        if (playlistSubmissions.length === 0) {
          this.allItemsLoaded = true;
          this.itemsLoading = false;
          return;
        }
        this.items.push(...playlistSubmissions);
        this.pagination = {
          ...this.pagination,
          pageOffset: this.pagination.pageOffset + 1,
        };
      } catch (error) {
        throw handleError(error);
      } finally {
        this.itemsLoading = false;
      }
    },

    selectSubmission(submission: PlaylistSubmission): void {
      this.selectedSubmission = submission;
    },

    async getFilteredItemsCount(query: any): Promise<{ total: number }> {
      try {
        const total = await service.getFilteredItemsCount(query);
        this.tmpSubmissionsCount = total;
        return { total };
      } catch (error) {
        throw handleError(error);
      }
    },

    async archive(
      playlistSubmissionId: string,
      progress: number,
    ): Promise<boolean> {
      try {
        this.items = this.items.filter(
          (item) => item._id !== playlistSubmissionId,
        );

        this.submissionsCount = this.submissionsCount - 1;

        await service.proAction(
          playlistSubmissionId,
          ProAction.ARCHIVE,
          progress,
        );
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },

    async unArchive(
      playlistSubmissionId: string,
      progress: number,
    ): Promise<boolean> {
      try {
        this.items = this.items.filter(
          (item) => item._id !== playlistSubmissionId,
        );

        this.submissionsCount = this.submissionsCount - 1;

        await service.proAction(
          playlistSubmissionId,
          ProAction.UNARCHIVE,
          progress,
        );
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },

    async save(
      playlistSubmissionId: string,
      progress: number,
    ): Promise<boolean> {
      try {
        this.items.forEach((playlistSubmission) => {
          if (playlistSubmission._id == playlistSubmissionId) {
            playlistSubmission.action!.push({
              action: ProAction.LIKE,
              pro: {
                firstName: "",
                lastName: "",
                _id: "",
              },
              active: true,
            });
          }
        });

        await service.proAction(playlistSubmissionId, ProAction.SAVE, progress);
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },

    async unSave(
      playlistSubmissionId: string,
      remove: boolean,
      progress: number,
    ): Promise<boolean> {
      try {
        if (remove) {
          this.items = this.items.filter(
            (item) => item._id !== playlistSubmissionId,
          );
        }

        this.submissionsCount = this.submissionsCount - 1;

        await service.proAction(
          playlistSubmissionId,
          ProAction.UNSAVE,
          progress,
        );
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },

    async like(
      playlistSubmissionId: string,
      progress: number,
    ): Promise<boolean> {
      try {
        await service.proAction(playlistSubmissionId, ProAction.LIKE, progress);
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },
    async superlike(
      playlistSubmissionId: string,
      progress: number,
    ): Promise<boolean> {
      try {
        await service.proAction(
          playlistSubmissionId,
          ProAction.SUPERLIKE,
          progress,
        );
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },

    getNext(currentId: string): PlaylistSubmission | undefined {
      const index = this.items.findIndex((item) => item._id === currentId);
      return this.items[index + 1];
    },

    async play(playlistSubmissionId: string): Promise<boolean> {
      try {
        await service.proAction(playlistSubmissionId, ProAction.PLAY, 0);
        return true;
      } catch (error) {
        throw handleError(error);
      }
    },
    async createPlaylistSubmission(
      trackId: string,
    ): Promise<PlaylistSubmission> {
      return service.createPlaylistSubmission(trackId);
    },
    async submitMessage(
      submissionId: string,
      message: string,
      progress: number,
    ) {
      try {
        await service.submitMessage(submissionId, message, progress);
      } catch (error) {
        throw handleError(error);
      }
    },

    selectFirstSubmission(): void {
      this.selectedSubmission = this.items[0];
    },

    async loadMoreSubmissions(query: any) {
      try {
        this.submissionsCount = this.tmpSubmissionsCount;

        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        if (!this.hasMore) {
          return;
        }
        const submissions = await service.list({
          start: this.total,
          length: this.infiniteScrollPageSize,
          ...query,
        });
        this.items = this.items.concat(submissions);
        this.total += this.infiniteScrollPageSize;

        if (submissions.length < this.infiniteScrollPageSize) {
          this.hasMore = false;
        }
      } catch (error) {
        this.hasMore = false;
        throw handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    async getSubmissions(
      query: WritableComputedRef<{
        [key: string]: string;
      }>,
    ): Promise<{ pending: globalThis.Ref<boolean> }> {
      try {
        const router = useRouter();
        let authToken;

        if (!process.server) {
          authToken = localStorage.getItem("authToken");
        }

        const { pending } = await useFetch<PlaylistSubmission[]>(
          `${config("apiBaseUrl")}/playlist-submission`,
          {
            query,
            key: `playlist-submission/${JSON.stringify(query.value)}`,
            watch: [() => query],
            onRequest: () => {
              if (router.currentRoute.value.name !== "discover") {
                return;
              }

              if (((query.value as { start?: string }).start || "0") === "0") {
                this.page = 0;
                this.items = [];
                this.submissionsCount = this.tmpSubmissionsCount;
              }
            },
            onResponse: ({ response }) => {
              if (router.currentRoute.value.name !== "discover") {
                return;
              }
              if (Object.keys(query.value).length === 0) {
                return;
              }

              if (
                this.previousQuery == JSON.stringify(query.value) &&
                this.items.length
              ) {
                return;
              }

              this.previousQuery = JSON.stringify(query.value);

              this.items = this.items.concat(response._data || []);

              const { start, length, ...filter } = query.value;
              if (Object.keys(filter).length)
                analyticsService.trackProAppliedFilter(
                  filter,
                  this.items.length,
                  "Music Library",
                );
            },
            headers: {
              ...(authToken && { Authorization: `Bearer ${authToken}` }),
            },
          },
        );

        return { pending };
      } catch (error) {
        throw handleError(error);
      }
    },

    async getSubmissionCount(
      query: Ref<Object>,
    ): Promise<{ pending: globalThis.Ref<boolean> }> {
      try {
        this.isLoading = true;
        let authToken;

        if (!process.server) {
          authToken = localStorage.getItem("authToken");
        }

        const { pending } = await useFetch<number>(
          `${config("apiBaseUrl")}/playlist-submission/count`,
          {
            params: query,
            key: `playlist-submission/count/${JSON.stringify(query.value)}`,
            watch: [() => query],
            onRequest: () => {},
            onResponse: ({ response }) => {
              this.tmpSubmissionsCount = response._data || 0;
            },
            headers: {
              ...(authToken && { Authorization: `Bearer ${authToken}` }),
            },
          },
        );

        return { pending };
      } catch (error) {
        throw handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  persist: [
    {
      key: "demoSubmissions",
      storage: persistedState.sessionStorage,
    },
  ],
});
